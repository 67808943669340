import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom"
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ReactSVG } from "react-svg";

// Images
import logo from "../assets/images/logo-nutriella.png";
import instaIcon from "../assets/images/instagram.svg";
import fbIcon from "../assets/images/facebook.svg";

let getdate = new Date();
let year = getdate.getFullYear();

export default function Footer(){
  return(
    <>
      <footer>
        <div className="footer-top">
          <div className="footer-image">
            <HashLink to="/#uvod"><LazyLoadImage src={logo} alt="logo" /></HashLink>    
          </div>
          <div className="footer-links">
            <div>
              <h5>Menu</h5>
              <ul>
                <li><HashLink smooth to="/#o-mne">O mně</HashLink></li>
                <li><HashLink smooth to="/#moje-sluzby">Služby</HashLink></li>
                <li><HashLink smooth to="/#kontakt">Kontakt</HashLink></li>
              </ul>
            </div>
            <div>
              <h5>Kontakt</h5>
              <ul>
                <li><a href="tel:+420724807357"><u>+420 724 807 357</u></a></li>
                <li><a href="mailto:nutriella@seznam.cz"><u>nutriella@seznam.cz</u></a></li>
              </ul>
            </div>
            <div>
              <h5>Sledujte mě</h5>
              <div className="socials-container">
                <a href="https://www.instagram.com/nutriella_udrzitelnehubnuti/" target="_blank" rel="noopener noreferrer"><ReactSVG src={instaIcon} className="icon-with-bg" /></a>
                <a href="https://www.facebook.com/PoradnaProVyzivuARedukciVahyNutriella" target="_blank" rel="noopener noreferrer"><ReactSVG src={fbIcon} className="icon-with-bg fb" /></a>  
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-copyright">
            <p>Copyright © {year} <HashLink to="/#uvod"><u>Nutriella</u></HashLink>. All Rights Reserved.</p>
          </div>
          <div className="footer-signature">
            <p>Code by <u><Link to="https://dominikplecity.cz"><u>Dominik Plecitý</u></Link></u></p>
          </div>
        </div>
      </footer>      
    </>
  )
}