import { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
// import { motion } from "framer-motion";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ReactSVG } from "react-svg";

// Components
import AccordionItem from "../components/AccordionItem";
import Form from "../components/Form";

// Images
// import loading_animation from "../assets/images/nutriella-loading.gif";
import hero from "../assets/images/uvod-hero.webp";

import introductionFotoDesktop from "../assets/images/uvod-profil/uvod-profil-desktop.webp";
import introductionFotoTablet from "../assets/images/uvod-profil/uvod-profil-tablet.webp";
import introductionFotoMobile from "../assets/images/uvod-profil/uvod-profil-mobile.webp";
 
import aboutFotoDesktop from "../assets/images/o-mne/o-mne-desktop.webp";
import aboutFotoTablet from "../assets/images/o-mne/o-mne-tablet.webp";
import aboutFotoMobile from "../assets/images/o-mne/o-mne-mobile.webp";

// Icons
import avocadoBg from "../assets/images/avocado.svg";
import instaIcon from "../assets/images/instagram.svg";
import fbIcon from "../assets/images/facebook.svg";
import benefit_icon from "../assets/images/benefit-icon.svg";

// Styles
// Default theme
import '@splidejs/react-splide/css';

// or other themes
import '@splidejs/react-splide/css/skyblue';
import '@splidejs/react-splide/css/sea-green';

// or only core styles
import '@splidejs/react-splide/css/core';

import "../assets/style/layout/home/home.css";


export default function Home(){
  const images = require.context('../assets/images/reference-foto/', true);
  const imageList = images.keys().map(image => images(image));
  const [ active, setActive ] = useState(null);

  const handleToggle = (index) => {
    if(active === index){
      setActive(null);
    }
    else{
      setActive(index);
    }
  };

  document.querySelector("#root").classList.remove("error-page");


  // Reference
  const reference = [
    {jmeno:"Dominika H.", text:"Lucinka je úžasná, hodně mě pomohla a poradila, těším se na pokračování, má pochopení a je lidská a Petruška na líčení je taky úžasná děkuji moc."},
    {jmeno:"Olga F.", text:"Během covidu, kdy se všechny mé oblíbené aktivity zavřely, jsem přibrala několik kg. Poté jsem otěhotněla a po porodu zůstala kila jak pocovidová, tak poporodní. Paní Marečkovou jsem vyhledala před Vánoci, kdy jsem si chtěla vybrat nějaké dárky v podobě oblečení a zjistila jsem, že mi skoro nic není nebo v tom vypadám příšerně Paní Marečková mě naučila, jak si sama sestavit jednotlivá jídla, a tak je můj jídelníček pestrý a mohu si vymýšlet stále nové a nové kombinace zdravé stravy děkuji."},
    {jmeno:"Jesika S.", text:"Dlouho jsem přemýšlela a vymýšlela, jak zatočit s posledními poporodními kily. Po brouzdání internetem a nacházením nesmyslných ketodiet a měsíčních cvičebních výzev, jsem narazila na paní Marečkovou. Její pozitivní přístup a výsledky klientů mě přesvědčil se objednat. Na schůzce bylo jasné, že je to přesně to, co hledám a co má pro mě smysl. Po prvním měsíci s viditelnými výsledky jsem byla spokojená a každá další kontrola mi motivovala dál. Díky vedení paní Marečkové mám zpět svoji váhu a taky sebevědomí. Takže kdo z vás ještě váha, neváhejte a jděte do toho, nebudete litovat!"},
    {jmeno:"Dominik D.", text:"Paní Marečková umí opravdu zázraky, naučila mě jak jíst všechno, neutrácet za BIO potraviny a hlavně nevyčítat si když si dopřeju něco dobrého, co v jídelníčku nemám. Lucinko moc Vám děkuju za vše."},
    {jmeno:"Terezie C.", text:"Paní Marečkovou mohu vřele doporučit. Dlouho jsem se trápila nadváhou, zkoušela různé diety, které sice měli efekt, ale pouze krátkodobý. Brzo se kila vrátila i s jojo efektem. Až paní Marečková mi ukázala, že ke štíhlé postavě se musí člověk projíst. Jídelníček se skládal z běžně dostupných potravin, byl jednoduchý na přípravu a jídla byla velmi chutná. Nejen, že jsem o několik kilo lehčí, ale mám spoustu energie a centimetry v nejrizikovější oblasti (břicho), mizí jako mávnutím kouzelného proutku. Tímto Vám chci moc poděkovat, že jste mě nasměrovala na tu správnou cestu a ukázala mi, jak se kvalitně i levně stravovat. Jste úžasná žena se na správném místě. Děkuji"},
    {jmeno:"Věra P.", text:"Nejde o kila, ale o pocit. Přišla jsem za Lucií s tím, že potřebuji poradit, jak se mám stravovat. Moje věčné problémy se střevy. Před 4 měsíci jsem si myslela, že dělám vše správně, ale teprve teď vím, že rada o odbornice, jakou je paní Marečková je nad zlato. Takže neváhejte a nechte si také poradit. A shozené centimetry a kila jsou tou nejlepší odměnou. Ještě jednou děkuji."},
    {jmeno:"Dana R.", text:"P. Marečkovou doporučuji všem, kteří chtějí ZHUBNOUT, ne jen stále hubnout...jídelníček je sestaven z běžně dostupných potravin a není nijak zvlášť finančně náročný. Jsem skoro půl roku po skončení redukčního programu, cítím se super a nepřibírám (ani po Vánocích). Největší odměnou za zhubnutí pro mě byl fakt, že obléknu již odložené kusy v šatníku, které mi předtím byly malé a reakce okolí. Tato investice do Vašeho zdraví se určitě vyplatí. PS: Máma i bracha také stále drží váhu. Děkujeme."}
  ];

  // FAQ
  const faqs = [
    {otazka:"Používáte při své práci nějaké doplňky stravy?", odpoved:"Ne, nepoužívám. Doplňky stravy sice řeší redukci, jako cokoliv, co pracuje s energetickým deficitem, ale nelze je jíst dlouhodobě a většinou po skončení redukce přichází jojo-efekt."},
    {otazka:"Budu muset dřít ve fitku nebo každý den cvičit?", odpoved:"Ne, nebudete. Své klienty nenutím cvičit nad rámec toho, co je jim příjemné. Redukční jídelníček vždy přizpůsobuji životnímu stylu klienta."},
    {otazka:"Používáte speciální potraviny?", odpoved:"Ne nepoužívám. Jídelníček je sestaven z běžně dostupných surovin, na které jsme v ČR zvyklí."},
    {otazka:"Je příprava pokrmů časově náročná?", odpoved:"Ne, není. Příprava hlavních pokrmů zabere max. 25 min."},
    {otazka:"Jsou recepty složité?", odpoved:"Ne, nejsou. Pokrmy zvládne uvařit i člověk, který má s vařením minimální zkušenosti."},
    {otazka:"Kolik kilogramů zhubnu?", odpoved:"Množství zredukovaných kilogramů závisí na výchozí váze. Na redukčním cíli a postupu redukce se vždy domluvím s klientem. Běžně klienti hubnou od 8 do 20 kg za 4 měsíce."},
    {otazka:"Dokážu si zredukovanou váhu udržet?", odpoved:"Ano. Pokud budete dodržovat základní rady, jako pravidelnost, přizpůsobíte množství jídla výdeji, budete dodržovat pitný režim a alespoň mírnou pohybovou aktivitu v podobě procházek, váhu si celoživotně udržíte, i když budete občas hřešit."}
  ];

  // Ceník služeb
  const cenikSluzeb = [
    {sluzba:"6 měsíční individuální redukční program či nabírání váhy", cena:"5 990"},
    {sluzba:"4 měsíční individuální redukční program či nabírání váhy", cena:"5 490"},
    {sluzba:"Individuální konzultace", cena:"60 min / 800"},
    {sluzba:"Diagnostika tělesných hodnot na přístroji OMRON", cena:"300"},
    {sluzba:"Sestavení individuálního jídelníčku na týden", cena:"2 000"},
    {sluzba:"Konzultace následné péče po skončení programu (15min.)", cena:"250"}
  ];

  //  initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1, y: 0 }} viewport={{ once: true }}  transition={{ ease: "circOut", duration: .6, delay: .4 }}

  return(
    <>
      <section id="uvod" data-menu-section>  
        <div className="uvod-text">
          <h1>Zdravý život začíná u talíře</h1>
          <p>Pomohu Vám zlepšit Vaše zdraví a získat zdravé sebevědomí.</p>
          <div className="cta-container">
            <HashLink to="#moje-sluzby" className="button-fill-dark">Moje služby</HashLink>
          </div>
        </div>
        <div className="uvod-foto">
          <picture>
            <source media="(min-width:992px)" srcSet={introductionFotoDesktop} width="1292" height="1400" />
            <source media="(min-width:768px)" srcSet={introductionFotoTablet} width="992" height="1200" />
            <img src={introductionFotoMobile} alt="Nutriční poradenstvi" loading="lazy" width="600" height="900" />
          </picture>      
        </div>
        <LazyLoadImage 
          src={hero}
          alt="hero image nutriella"
          className="uvod-hero" />
      </section>
      <section id="benefity">
        <h2>Pomohla jsem již tisícům klientů</h2>
        <p>Díky osvědčeným metodám a individuálnímu přístupu jsem pomohla mnoha lidem dosáhnout svých cílů a změnit tak jejich život k lepšímu.</p>
        <div className="skills">
          <div>
            <h4>23</h4>
            <p>let zkušeností</p>
          </div>
          <div>
            <h4>1500+</h4>
            <p>spokojených klientů</p>
          </div>
          <div>
            <h4>13000+</h4>
            <p>shozených kil</p>
          </div>
        </div>
      </section>
      <section id="moje-sluzby" data-menu-section>
        <h2>Moje služby</h2>
        <p>Pomohu Vám cítit se fajn, zlepšit Vaše zdraví a posílit sebevědomí.</p>
        <div className="program-services">
          <div className="program-service">
            <h4>4 měsíční program</h4>
            <p className="bold">Program zahrnuje</p>
            <ul className="services-list">
              <li>Sestavení výživového plánu na míru s týdenním jídelníčkem</li>
              <li>Praktické rady pro sestavování vlastního jídelníčku</li>
              <li>Změření skladby těla na diagnostickém přístroji OMRON</li>
              <li>Zprávu o naměřených hodnotách</li>
              <li>Edukace v oblasti výživy a zdravého životního stylu</li>
              <li>Doporučení fyzické aktivity na základě fyzické kondice a zdravotního stavu</li>
              <li>Kontrolní vážení a měření 1x za 3 týdny</li>
              <li>Po celou dobu redukce on-line servis – e-&shy;mail, telefon</li>
              <li>V závěru programu doporučení pro udržení váhy</li>
              <li>Celkem 6 konzultací</li>
            </ul>
            <div className="dotted-line"></div>
            <HashLink to="#kontakt" className="button-nofill-light">Začít se svojí změnou</HashLink>
          </div>
          <div className="program-service popular">
            <h4>6 měsíční program</h4>
            <div className="popular-sign">Populární</div>
            <p className="bold">Program zahrnuje</p>
            <ul className="services-list">
              <li>Vše co 4 měsíční program</li>
              <li>O 3 konzultace více (Celkem 9)</li>
            </ul>
            <div className="dotted-line"></div>
            <HashLink to="#kontakt" className="button-fill-white">Začít se svojí změnou</HashLink>
          </div>
        </div>
      </section>
      <section id="s-cim-vam-pomohu">
        <div className="pomoc-content">
          <h2>Pomohu Vám zlepšit zdraví a dosáhnout ideální postavy</h2>
          <ul className="checkpoints">
            <li>Nastavíme spolu Vaše stravování tak, aby bylo pro Vás co nejpříjemnější</li>
            <li>Nebudu Vás nutit cvičit</li>
            <li>Naše spolupráce bude skloubení Vašich požadavků s mými zkušenostmi</li>
          </ul>
          <p className="cta-paragraph">Vstupte na cestu ke zdravému a spokojenému životu s mým nutričním plánem.</p>
          <div>
          <HashLink to="#o-mne" className="button-fill-light">O mně</HashLink>
          </div>
        </div>
        <div className="pomoc-foto">
          <ReactSVG src={benefit_icon} />  
        </div>
      </section>
      <section id="o-mne" data-menu-section>
        <div className="o-mne-content">
          <h2>O mně</h2>
          <p>Jako bývalá učitelka výživy na střední škole s 23 letou praxí své nutriční poradenství zásadně stavím na zdravém selském rozumu a základních vědeckých poznatcích o potřebách našeho organismu v rámci energetické bilance a udržení si zdraví a optimální váhy. Nepracuji s žádnými výživovými doplňky, speciálními potravinami ani dietami. Snažím se své klienty naučit, jak zdravě přistupovat k jídlu a svému tělu. Nenutím nikoho za každou cenu cvičit, pokud mu není pohyb vlastní a konzumovat potraviny, které nemá rád. Mou snahou je to, aby klienti po konci redukčního programu chápali problematiku příjmu a výdeje, rovnováhu v příjmu makroživin a dokázali si dál, i bez mé pomoci, celoživotně optimální váhu udržet.</p>
          <div>
            <HashLink to="#jak-to-u-me-funguje" className="button-fill-dark">Jak funguje spolupráce</HashLink>
          </div>
        </div>
        <div className="o-mne-foto" >
          <picture>
            <source media="(min-width:992px)" srcSet={aboutFotoDesktop} width="1292" height="1400" />
            <source media="(min-width:768px)" srcSet={aboutFotoTablet} width="992" height="1200" />
            <img src={aboutFotoMobile} alt="Lucie Marečková" loading="lazy" width="600" height="900" />
          </picture>
        </div>
        <img src={avocadoBg} className="o-mne-background" alt="" />
      </section>
      <section id="jak-to-u-me-funguje">
        <h2 >Jak to u mě funguje</h2>
        <div className="jak-to-funguje-container">
          <div className="step">
            <div className="step-number">1</div>
            <h3>Vstupní konzultace</h3>
            <p>Vyplnění dotazníku ohledně životního stylu, pohybu a stravování, diagnostika tělesného složení na bioimpedanční váze.</p>
          </div>
          <div className="step">
            <div className="step-number">2</div>
            <h3>Sestavení redukčního plánu s jídelníčkem</h3>
          </div>
          <div className="step">
            <div className="step-number">3</div>
            <h3>Pravidelné kontroly po 3 týdnech</h3>
            <p>Vážení, měření, edukace v oblasti tvorby vlastních receptur.</p>
          </div>
          <div className="step">
            <div className="step-number">4</div>
            <h3>Závěrečná konzultace</h3>
            <p>Celkové zhodnocení dosažených výsledků, nastavení udržovacího programu.</p>
          </div>
          <div className="step">
            <div className="step-number">5</div>
            <h3>Proměna</h3>
            <p>Vizážistka, závěrečné focení</p>
          </div>
        </div>
      </section>
      <section id="reference" data-menu-section>
        <h2 >Co říkají moji klienti</h2>
        <Splide className="reference-container"
          options={{
            type: 'loop',
            gap: '3em',
            perPage: 2,
            focus: 0,
            drag: false,
            lazyLoad: 'nearby',
            autoplay: true,
            autoScroll: {
              pauseOnHover: true,
              pauseOnFocus: false,
              speed: 8
            },
            breakpoints: {
              888: {
                perPage: 1
              }
            }
          }}
          aria-label="reference carousel">
            {reference.map((jednareference, index) => (
              <SplideSlide key={index} className="reference-card">
              <div className="reference-text">
                <p>{jednareference.text}</p>
                <h4>{jednareference.jmeno}</h4>
              </div> 
            </SplideSlide>
            ))}      
        </Splide>
        <h3>Fotografie klientů</h3>
        <Splide className="reference-container"
          options={{
            type: "loop",
            gap: "3em",
            drag: true,
            arrows: false,
            pagination: false,
            perPage: 3,
            autoScroll: {
              pauseOnHover: false,
              pauseOnFocus: false,
              rewind: false,
              speed: .5
            },
            breakpoints: {
              888: {
                perPage: 1
              }
            }
          }}
          extensions={{ AutoScroll }}
          aria-label="reference carousel">
            {imageList.map((fotoReference, index) => (
              <SplideSlide key={index} className="reference-card">
              <img src={fotoReference} alt="fotografie klienta před/po" />
            </SplideSlide>
            ))}      
        </Splide>
        <div className="reference-call-to-action">
          <HashLink to="#casto-kladene-dotazy" className="button-fill-dark">Nejčastější dotazy</HashLink>
        </div>
      </section>
      <div className="green-background">
        <section id="casto-kladene-dotazy" data-menu-section>
          <div className="faq-heading">
            <h2>Často kladené dotazy</h2>
            <HashLink to="#kontakt" className="button-fill-dark">Chci si domluvit schůzku</HashLink>
          </div>
          <div className="faq-container">
            {faqs.map((faq, index) => (
              <AccordionItem
                key={index} 
                id={index}
                heading={faq.otazka}
                text={faq.odpoved}
                active={active}
                handleToggle={handleToggle} />
            ))}
          </div>
        </section>
        <section id="oteviraci-doba">
          <h2>Otevírací doba</h2>
          <p>Otevírací doba je dle objednávek. Nemám pevně stanovené otevírací hodiny, abych Vám mohla nabídnout individuální a personalizovaný přístup. Domluvte si schůzku ještě dnes v termínu, který Vám vyhovuje.</p>
          <HashLink to="#kontakt" className="button-fill-dark">Chci si domluvit schůzku</HashLink>
        </section>
      </div>
      <section id="cenik" data-menu-section>
        <h2>Ceník služeb</h2>
        <div className="cenik-sluzeb-container">
          {cenikSluzeb.map((cenik, index) => (
            <div className="cenik-sluzeb-item" key={index}>
              <span className="popis-ceny-sluzby"><h4>{cenik.sluzba}</h4></span>
              <span className="dots"></span>
              <span className="cena-sluzby"><p>{cenik.cena},-</p></span>
            </div>
          ))}
        </div>
      </section>
      <section id="kontakt" data-menu-section>
        <div className="kontakt-container">
        <div className="kontakt-left">
          <h2>Kontakt</h2>
          <p className="kontakt-ponuka">Ráda Vám pomohu zlepšit Váš životní styl. Pokud máte otázky ohledně výživy, stravovacích návyků nebo chcete získat informace o mých službách, neváhejte mě kontaktovat.</p>
          <div className="kontakt-info">
            <div>
              <h5>Kontaktujte mě</h5>
              <ul>
                <li><Link to="tel:+420724807357"><u>+420 724 807 357</u></Link></li>
                <li><Link to="mailto:nutriella@seznam.cz"><u>nutriella@seznam.cz</u></Link></li>
                <li>IČO: <span className="light-color">45380163</span></li>
              </ul>
            </div>
            <div>
              <h5>Sídlo</h5>
              <p>Terezie Brzkové 1531800 Plzeň - Zdravcentrum Skvrňany</p>
            </div>
          </div>
          <div className="kontakt-socials">
              <h5>Sledujte mě</h5>
              <div className="socials-container">
                <Link to="https://www.instagram.com/nutriella_udrzitelnehubnuti/" target="_blank" rel="noopener noreferrer"><ReactSVG src={instaIcon} className="icon-with-bg" /></Link>
                <Link to="https://www.facebook.com/PoradnaProVyzivuARedukciVahyNutriella" target="_blank" rel="noopener noreferrer"><ReactSVG src={fbIcon} className="icon-with-bg fb" /></Link>  
              </div>
            </div>
        </div>
        <div className="kontakt-right">
          <Form successHeading="Děkuji za poslání zprávy" successParagraph="Kontaktuji Vás hned jak to bude možné" failureHeading="Nepovedlo se odeslat zprávu" failureParaghraph="Zkuste to prosím později" />
        </div>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1116.9269513988652!2d13.32731712810105!3d49.74215152448599!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470aed8a5b0a6555%3A0xb2eee069e879c422!2sZdravcentrum%20Skvr%C5%88any%20S.r.o.!5e1!3m2!1scs!2scz!4v1685184495371!5m2!1scs!2scz" width="600" height="450" loading="lazy" referrerPolicy="no-referrer-when-downgrade" className="map" title="map-office"></iframe>
      </section>
    </>
  )
}