import { useRef } from "react";

import arrowDown from "../assets/images/arrow-down.svg";

export default function AccordionItem(props){
  const contentEl = useRef(null);
  const { id, heading, text, handleToggle, active } = props;

  return(
    <>
      <div className={active === id ? "accordion-item-header active" : "accordion-item-header"}
        onClick={() => handleToggle(id)}>
        <h5 className="disable-select">{heading}</h5>
        <img src={arrowDown} alt="arrow down" />
      </div>  
      <div ref={contentEl}
        className={`collapse ${active === id ? "show" : ""}`}
        style={active === id ? { height: contentEl.current.scrollHeight } : { height: "0px" }}>
        <p>{text}</p>
      </div>
    </>
  )
}