import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { LazyLoadImage } from "react-lazy-load-image-component";

import logo from "../assets/images/logo-nutriella.png"

export default function Navbar(){

  // hamburger menu
  const [burgerClass, setBurgerClass] = useState("burger-bar")
  const [menuClass, setmenuClass] = useState("")
  const [isMenuClicked, setIsMenuClicked] = useState(false)

  const updateMenu = () => {
    if(!isMenuClicked){
      setBurgerClass("burger-bar clicked")
      setmenuClass("header-clicked")
      document.querySelector("body").classList.add("frozen");
    }
    else{
      setBurgerClass("burger-bar")
      setmenuClass("")
      document.querySelector("body").classList.remove("frozen");
    }
    setIsMenuClicked(!isMenuClicked)
  }

  // zavření menu po kliknutí na odkaz
  const closeMenu = () => {
    if (isMenuClicked) {
      setBurgerClass("burger-bar");
      setmenuClass("");
      document.querySelector("body").classList.remove("frozen");
      setIsMenuClicked(false);
    }
  };

  // změna stylování hlavičky po scrollnutí
  useEffect(() => {
    const changeHeaderDesign = () => {
      const header = document.querySelector("header");
      if(window.scrollY > 0){ 
        header.classList.add("scrolled");
      }
      else{
        header.classList.remove("scrolled");
      }
    }
    
    window.addEventListener('scroll', changeHeaderDesign);

    return () => window.removeEventListener('scroll', changeHeaderDesign);
  }, []);

  return (
    <header className={menuClass}>
      <HashLink to="/#uvod" className="site-logo" onClick={closeMenu}>
        <LazyLoadImage 
          src={logo}
          alt="logo nutriella" />
      </HashLink>
      <nav className="primary-navigation">
        <ul className="nav-ul">
          <ActiveSection to="/#uvod" value="Úvod" closeMenu={closeMenu} />
          <ActiveSection to="/#moje-sluzby" value="Moje služby" closeMenu={closeMenu} />
          <ActiveSection to="/#o-mne" value="O mně" closeMenu={closeMenu} />
          <ActiveSection to="/#reference" value="Reference" closeMenu={closeMenu} />
          <ActiveSection to="/#casto-kladene-dotazy" value="FAQ" closeMenu={closeMenu} />
          <ActiveSection to="/#cenik" value="Ceník" closeMenu={closeMenu} />
          <ActiveSection to="/#kontakt" value="Kontakt" closeMenu={closeMenu} />
        </ul>
      </nav>
      <div className="nav-actions">
        <HashLink to="/#kontakt" className="button-fill-dark" onClick={closeMenu}>Chci schůzku</HashLink>
        <div onClick={updateMenu} className={burgerClass}>
          <span className="top-line"></span>
          <span className="mid-line"></span>
          <span className="bottom-line"></span>
        </div>
      </div>
    </header>
  )
}

// vyznačení jaká sekce je aktivní
function ActiveSection(props){
  const { to, value, closeMenu } = props;

  const [activeSection, setActiveSection] = useState('/#uvod');

  useEffect(() => {
    const handleScroll = () => {
      let current = '';
      const sections = document.querySelectorAll('[data-menu-section]');

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        if (window.pageYOffset >= sectionTop - 500) {
          current = '/#'+section.getAttribute('id');
        }
      });

      setActiveSection(current);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  const location = useLocation();

  useEffect(() => {
    if(location.pathname !== '/'){
      setActiveSection('');
    }
  }, [location]);

  return(
    <li className={activeSection === to ? 'active' : ''}>
      <HashLink smooth to={to} onClick={closeMenu}>{value}</HashLink>
    </li>
  )
}

/*
function CustomLink({ to, children, ...props }){

  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return(
    <li className={isActive ? "active": ""}>
      <Link to={to} {...props}>{children}</Link>
    </li>     
  )
}*/