import { useState } from "react";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";

// Images
import exclamationMark from "../assets/images/circle-exclamation-solid.svg";
import exclamationMark2 from "../assets/images/exclamation.svg";
import like from "../assets/images/like.svg";
import loadingIcon from "../assets/images/loading.svg";

export default function Form(prop){
    const { successHeading, successParagraph, failureHeading, failureParaghraph } = prop;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [emptyName, setEmptyName] = useState(false);
    const [emptyEmail, setEmptyEmail] = useState(false);
    const [emptyMessage, setEmptyMessage] = useState(false);
    const [popupView, setPopupView] = useState(false);
    const [isSend, setIsSend] = useState(false);
    const [loading, setLoading] = useState(true);
    
    // const navigate = useNavigate();
  
    const handleSubmit = (e) => {
      e.preventDefault();

      if(!email){
        setEmptyEmail(true); 
      }
      if(!name){
        setEmptyName(true);
      }
      if(!message){
        setEmptyMessage(true);
      }
      if(email && name && message){
        setEmptyName(false); 
        setEmptyEmail(false);
        setEmptyMessage(false);

        setPopupView(true);
        document.querySelector('body').classList.add("frozen");

        axios.post(`${process.env.REACT_APP_BACKEND_API}/sendemail`, {email, name, message}, {headers: {'access-key': process.env.REACT_APP_ACCESS_KEY}})
        .then((res) => {
          setLoading(false);
          setIsSend(true);
          setName('');
          setEmail('');
          setMessage('');
          document.querySelector("#vase-jmeno").value = "";
          document.querySelector("#vas-email").value = "";
          document.querySelector("#vase-zprava").value = "";
        })
        .catch((err) => {
          setLoading(false);
          setIsSend(false);
        })
      }
    }

    const closePopup = () => {
      setPopupView(false); 
      document.querySelector('body').classList.remove("frozen"); 
    }

    const disableFuncition = () => {}

    return(
      <>
        <form onSubmit={handleSubmit}>
          <div className="input-wrapper">
            <div className="input-content">
              <label htmlFor="vase-jmeno">Vaše jméno</label>
              <input type="text" id="vase-jmeno" name="vase-jmeno" placeholder=" " onChange={(e) => setName(e.target.value)} />
              <div className="form-message">{emptyName ? <ReactSVG src={exclamationMark} /> : null}{emptyName ? 'Vyplňte Vaše jméno' : null}</div>
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-content">
              <label htmlFor="vas-email">Váš e-mail</label>
              <input type="email" id="vas-email" name="vas-email" placeholder=" " onChange={(e) => setEmail(e.target.value)} />
              <div className="form-message">{emptyEmail ? <ReactSVG src={exclamationMark} /> : null}{emptyEmail ? 'Vyplňte Váš e-mail' : null}</div>    
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-content">
              <label htmlFor="vase-zprava">Vaše zpráva</label>
              <textarea id="vase-zprava" name="vase-zprava" placeholder=" " onChange={(e) => setMessage(e.target.value)} rows="5"></textarea>
              <div className="form-message">{emptyMessage ? <ReactSVG src={exclamationMark} /> : null}{emptyMessage ? 'Napište Váš dotaz' : null}</div>
            </div>
          </div>
          <input type="submit" value="Odeslat zprávu" className="button-fill-light" />
        </form>
        <div id={isSend ? 'success-message' : 'popup'} className={popupView ? 'popup show' : 'popup'} onClick={loading ? disableFuncition : closePopup}>
          <div className="popup-box">
            <div className="popup-icon">
              {loading ? <ReactSVG src={loadingIcon} /> : isSend ? <ReactSVG src={like} /> : <ReactSVG src={exclamationMark2} /> }
            </div>
            <div className="popup-message">
              <h4>{loading ? "Odesílání zpávy..." : isSend ? successHeading : failureHeading}</h4>
              <p>{loading ? "Prosím vyčkejte vteřinku" : isSend ? successParagraph : failureParaghraph}</p>
              <button className={loading ? "button-nofill-light none" : "button-nofill-light"} onClick={loading ? disableFuncition : closePopup}>Zavřít</button>
            </div>
          </div>
        </div>
      </>
    )
}