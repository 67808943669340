import { Link } from "react-router-dom";

// Styles
import "../assets/style/layout/pageNotFound/pageNotFound.css";

// Images
// import loading_animation from "../assets/images/nutriella-loading.gif";

export default function PageNotFound(){

  document.querySelector("#root").classList.add("error-page")

  return(
    <>
      <div className="error-page-wrapper">
        <h2>Je mi líto, ale stránku se nepodařilo najít</h2>
        <p>Zkontrolujte zadanou URL adresu nebo se zorientujte na hlavní stránce.</p>
        <Link to="/#uvod" className="button-fill-dark">Přejít na úvodní stránku</Link>
      </div>
    </>
  )
}