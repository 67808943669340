export default function CookiePolicy(){
  return(
    <>
      <div>
        <h1>Cookie Policy</h1>
        <h4>Co jsou Cookies</h4>
        <p>Cookies jsou malé soubory, které se stahují do vašeho počítače, aby se zlepšila vaše zkušenost. Tato stránka popisuje, jaké informace shromažďují, jak je používáme a proč někdy potřebujeme ukládat tyto soubory cookie. Podělíme se také o to, jak můžete zabránit ukládání těchto souborů cookie, což však může snížit nebo „narušit“ určité prvky funkčnosti stránek.</p>
      </div>
    </>
  );
}